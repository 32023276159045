import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "input text-left" }
const _hoisted_2 = ["innerHTML"]
const _hoisted_3 = {
  key: 2,
  class: "flex input__field input__field--readonly"
}
const _hoisted_4 = ["innerHTML"]
const _hoisted_5 = {
  key: 3,
  class: "input__field--error_message",
  innerHTML: 'Du skal vælge fra listen'
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AutocompleteComponent = _resolveComponent("AutocompleteComponent")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", _hoisted_1, [
      (_ctx.label)
        ? (_openBlock(), _createElementBlock("label", {
            key: 0,
            class: "input__label",
            innerHTML: _ctx.label
          }, null, 8, _hoisted_2))
        : _createCommentVNode("", true),
      (!_ctx.readonlyValue)
        ? (_openBlock(), _createElementBlock("div", {
            key: 1,
            class: _normalizeClass([
					{ 'input__field--error': _ctx.showError },
					{ 'input__field--valid': _ctx.inputValid },
					{ 'input__field--searching': _ctx.searching },
					{ 'input__field__search-icon': _ctx.searchReady },
				])
          }, [
            _createVNode(_component_AutocompleteComponent, {
              "autocomplete-name": "address",
              "autocomplete-native": "off",
              "autocomplete-id": _ctx.autocompleteId,
              results: _ctx.results,
              "ac-ref": 'zipCity',
              placeholder: _ctx.placeholder,
              max: 10,
              "input-class": ['input__field'],
              "display-item": _ctx.displayItem,
              onInput: _ctx.search,
              onOnSelect: _ctx.handleSubmit,
              onBlur: _ctx.blur,
              onFocus: _ctx.onFocus,
              onAutoCompleteField: _ctx.setAutoCompleteField
            }, null, 8, ["autocomplete-id", "results", "placeholder", "display-item", "onInput", "onOnSelect", "onBlur", "onFocus", "onAutoCompleteField"])
          ], 2))
        : (_openBlock(), _createElementBlock("div", _hoisted_3, [
            _createElementVNode("div", { innerHTML: _ctx.readonlyValue }, null, 8, _hoisted_4)
          ])),
      (_ctx.showError)
        ? (_openBlock(), _createElementBlock("span", _hoisted_5))
        : _createCommentVNode("", true)
    ])
  ]))
}