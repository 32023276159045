import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, withCtx as _withCtx } from "vue"

const _hoisted_1 = ["innerHTML"]
const _hoisted_2 = { class: "text-center" }
const _hoisted_3 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_buttonComponent = _resolveComponent("buttonComponent")!
  const _component_modalComponent = _resolveComponent("modalComponent")!

  return (_ctx.showModal)
    ? (_openBlock(), _createBlock(_component_modalComponent, {
        key: 0,
        id: _ctx.modal.id,
        padding: "0",
        dismisable: true,
        "tracking-enabled": false,
        "use-modal-trigger": false
      }, {
        header: _withCtx(() => [
          _createElementVNode("div", {
            class: "h2 text-center",
            innerHTML: _ctx.modal.title
          }, null, 8, _hoisted_1)
        ]),
        body: _withCtx(() => [
          _createElementVNode("div", _hoisted_2, [
            _createElementVNode("div", {
              innerHTML: _ctx.modal.content
            }, null, 8, _hoisted_3),
            (_ctx.modal.btnInlineLabel)
              ? (_openBlock(), _createBlock(_component_buttonComponent, {
                  key: 0,
                  "btn-class": _ctx.modal.btnInlineStyle,
                  label: _ctx.modal.btnInlineLabel,
                  onButtonComponent: _cache[0] || (_cache[0] = ($event: any) => (_ctx.handleInlineAction()))
                }, null, 8, ["btn-class", "label"]))
              : _createCommentVNode("", true)
          ])
        ]),
        footer: _withCtx(() => [
          _createVNode(_component_buttonComponent, {
            "btn-class": _ctx.modal.btnSecondStyle,
            "btn-type": 'button',
            label: _ctx.modal.btnSecondLabel,
            onButtonComponent: _cache[1] || (_cache[1] = ($event: any) => (_ctx.closeModal()))
          }, null, 8, ["btn-class", "label"]),
          _createVNode(_component_buttonComponent, {
            "data-mainbtn": "",
            "btn-class": _ctx.modal.btnStyle,
            "btn-type": _ctx.modal.btnType,
            label: _ctx.getLabel(),
            icon: _ctx.getIcon(),
            target: _ctx.getTarget(),
            href: _ctx.getHrefType() + _ctx.modal.btnAction,
            onButtonComponent: _cache[2] || (_cache[2] = ($event: any) => (_ctx.track()))
          }, null, 8, ["btn-class", "btn-type", "label", "icon", "target", "href"])
        ]),
        _: 1
      }, 8, ["id"]))
    : _createCommentVNode("", true)
}