import { Options, Vue } from 'vue-class-component';
import { isApp } from '@/services/environmentService';
import store from '@/store/store';

@Options({
	name: 'CookieFooter',
	components: {},
})
export default class CookieFooter extends Vue {
	public get isThemed() {
		return store.getters.getThemeContext;
	}

	public isLoggedInAsApp() {
		return isApp;
	}
}
