import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ReceiptComponent = _resolveComponent("ReceiptComponent")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("span", {
      class: _normalizeClass([{ 'spinner-full-page': _ctx.showSpinner }])
    }, null, 2),
    _createVNode(_component_ReceiptComponent, { model: _ctx.model }, null, 8, ["model"])
  ], 64))
}