import { Vue, Options } from 'vue-class-component';
import { PropType } from 'vue';
import store from '@/store/store';
import { UsabillaInPageBlock } from '@/cms/definitions/content-types';

@Options({
	name: 'usabillaInPageComponent',
	props: {
		model: Object as PropType<UsabillaInPageBlock>,
		// contentItem: Object as PropType<any>,
		headlineClasses: String,
		'data-epi-block-id': String,
	},
})
export default class UsabillaInPageComponent extends Vue {
	model: UsabillaInPageBlock;
	// contentItem: any;
	headlineClasses: string;

	private static waiting = false;
	beforeCreate() {
		store.dispatch('setUsabillaCount');
	}

	mounted() {
		// tell store that one usabilla div is mountedreloadUsabillas(
		store.dispatch('setUsabillaMounted');
		UsabillaInPageComponent.reloadUsabillas(this);
	}

	public static reloadUsabillas(usabillaInPageComponent?: UsabillaInPageComponent) {
		// no usabilla component present
		if (!usabillaInPageComponent) {
			return;
		}
		// check if this is the last mounted usabilla div
		const usabilla = store.getters.getUsabillaState;
		const site = usabillaInPageComponent.model?.siteId ? usabillaInPageComponent.model.siteId : 'e9e9f96ce675';

		// console.log('calling reloadUsabillas', usabilla.mounted, usabilla.count, (window as any).usabilla !== undefined);
		if (usabilla.mounted === usabilla.count && (window as any).usabilla) {
			if (UsabillaInPageComponent.waiting) {
				// console.log('skipping usabillas');
				return;
			}
			UsabillaInPageComponent.waiting = true;

			setTimeout(() => {
				const iframes = document.querySelectorAll('[ub-in-page] iframe') as any;
				// console.log('usabillas', iframes);
				if (iframes && iframes.length && (window as any).usabilla) {
					iframes.forEach((element) => {
						element.parentNode.removeChild(element);
					});
				}

				// console.log('reloading usabilla script');
				(window as any).usabilla.load('w.usabilla.com', site);
				UsabillaInPageComponent.waiting = false;
			}, 1000);
		}
	}
}
