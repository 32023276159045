import { isDevelop, isLocalHost } from '@/services/environmentService';
import { Vue, Options } from 'vue-class-component';

@Options({
	name: 'TestPage',
})
export default class PageComponentSelector extends Vue {
	public carArray = [];
	public isLocalhost = isLocalHost;

	public mounted(): void {
		this.carArray.push([
			'9691309470 - Codan customer with Almbrand Consent call',
			'?quote=0Q03M000000BOO8SAO&customer_no=9691309470&signature=548b52191113c0c4c3a29ef3b64f194723ea65b755dd3cd3612cd0113333a004',
		]);

		// this.carArray.push([
		// 	9678927778,
		// 	'?quote=0Q03M000000BOFESA4&customer_no=9678927778&signature=340011967c82370c636dba9bb5870faf6566ee1444af986e6d465edbae1d093c',
		// ]);

		//https://preprod-api.almbrand.dk/quote-accept/quote/0Q03M000000BOFESA4/consents?customer_no=9678927778&signature=340011967c82370c636dba9bb5870faf6566ee1444af986e6d465edbae1d093c
		// this.carArray.push([
		// 	9690977599,
		// 	'?quote=1234&customer_no=9690977599&signature=26f06731aae33355a7e5b31233d5640847d3935ffbadf22f6b047655892b228d',
		// ]);
		// this.carArray.push([
		// 	9690254254,
		// 	'?quote=1234&customer_no=9690254254&signature=048b5a06172755bd4b33f139b99dc663e3d2ebee5f6440cba3596f4fb6f54458',
		// ]);
		// this.carArray.push([
		// 	9641500711,
		// 	'?quote=0Q08E000003KoqjSAC&customer_no=9641500711&signature=8d35858eee8c6bb7155f995d49f9eb21b4f93f379a8c04d3190c9a4ef6245953',
		// ]);
	}
}
