export const isInView = (id: string, top = -10): boolean => {
	// console.log('scrollto', id);
	const elem = document.getElementById(id);

	if (!elem) {
		return true;
	}
	const bounding = elem.getBoundingClientRect();
	return bounding.top >= top && bounding.bottom + 35 <= (window.innerHeight || document.documentElement.clientHeight);
};
