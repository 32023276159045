import { Options, Vue } from 'vue-class-component';
import store from '@/store/store';
import { nextTick } from 'vue';
import VueScrollTo from 'vue-scrollto';
import { mapState } from 'vuex';
import VerticalStepComponent from './VerticalStepComponent.vue';
import { ADD_PROPOSAL_STEPS, PROPOSAL_INFO_ERROR } from '@/store/modules/ProposalContext';
import { isInView } from '@/services/uiService';
import { getInsuranceCompanies, getProposal, submitConsent, submitProposal } from '@/services/apiService';
import ModelMapper from './ModelMapper';
import { formatterDigit } from '@/services/FormatService';
import { updateConsentFromApi } from '@/services/consentService';
import { isDevelop } from '@/services/environmentService';

@Options({
	name: 'VerticalContainerComponent',
	components: {
		VerticalStepComponent,
	},
	computed: mapState<any>({
		// steps: state => state.ProposalContext.steps,
		party: (state) => state.ProposalContext.party,
		quote_id: (state) => state.ProposalContext.quote_id,
		price: (state) => state.ProposalContext.price,
	}),
	props: {
		model: Object,
	},
})
export default class VerticalContainerComponent extends Vue {
	private submitTime = 'NA';
	public model: any;

	// public steps!: Map<string, any>;
	public party!: any;
	public quote_id!: string;
	public price!: number;
	public customerInfoLabel!: string;

	public renderPage = false;
	private waitActive = true;
	private activeStep = undefined;

	public visibleError = false;
	public proposalSteps = [];
	public loadError = null;

	public created() {
		window.addEventListener('keyup', this.moveOn);
	}
	public beforeUnmount() {
		window.removeEventListener('keyup', this.moveOn);
	}

	public moveOn(evt) {
		if (evt.keyCode === 13 && this.activeStep.isValid) {
			this.nextStep(this.activeStep);
		}
	}

	public async mounted() {
		try {
			store.state.showSpinner = true;

			const query = this.$route.query;
			if (query) {
				store.state.ProposalContext.quote_id = query.quote as string;
				store.state.ProposalContext.customerNo = query.customer_no as string;
				store.state.ProposalContext.signatureId = query.signature as string;
			}

			const proposal = await getProposal();
			if (isDevelop && proposal?.status === 'TEST_DATA') {
				this.model.mockedDataLoaded = true;
				store.state.ProposalContext.quote_id = 'MOCK ' + proposal.data.quote_id;
				store.state.ProposalContext.customerNo = 'MOCK custNo';
				store.state.ProposalContext.signatureId = 'dummy signatureID';
			}

			const insuranceCompanies = await getInsuranceCompanies();
			store.state.ProposalContext.insuranceCompanies = insuranceCompanies?.data?.insurance_companies;

			store.state.ProposalContext.originalRequest = proposal.data;
			const modelMapper: ModelMapper = new ModelMapper(proposal, this.model);
			this.proposalSteps = modelMapper.getProposalSteps(); // TODO explicit type

			await store.dispatch(ADD_PROPOSAL_STEPS, this.proposalSteps);

			// get user consent choses from API - no need to wait
			updateConsentFromApi();

			this.activeStep = store.getters.getActiveProposalStep;
			// this.scrollMeTo(this.activeStep);

			this.renderPage = true;
			store.state.showSpinner = false;
			// make sure activeStep is not loaded immediately - enables transition
			nextTick(() => {
				this.waitActive = false;
			});
			// store.state.modal = this.testSignicatModal();
		} catch (error) {
			console.error(error);
			store.state.showSpinner = false;
			nextTick(() => {
				this.loadError = 'Tilbud kunne ikke hentes';
			});
		}
	}

	public getSubtitle(step: any) {
		return step.subtitle;
	}

	public isActiveStep(step: any): boolean {
		return step.id === this.activeStep?.id && !this.waitActive;
	}

	public getCustomerInfo() {
		// const customerInfo = `<strong>Tilbudnummer:</strong> ${this.quote_id}
		//     <div class="flex flex__space">
		//         <span><strong>Dine oplysninger:</strong> ${this.party.name}</span><span><strong>${this.formatter.format(this.price)} kr./år</strong></span>
		//     </div>`;
		// TODO KLK Receipt
		const customerInfo = `<strong>${this.model.offerIdLabel}</strong> ${this.quote_id}
            <div class="flex flex__space">
                <span><strong>${this.model.customerInfoLabel}</strong> ${this.party.name}</span>
            </div>`;
		return customerInfo;
	}
	public isValid(step: any): boolean {
		return step.isValid;
	}

	public async gotoStep(step: any): Promise<void> {
		// TODO await updateConsentFromApi(); when consentBlock is pressed on
		// TODO await submitConsentToApi(); when consentBlock is currentStep and customer is leaving

		const previousStepsValid: boolean = store.getters.getIsPreviousProposalStepsValid(step.id);
		if (previousStepsValid) {
			this.activeStep = step;
			this.scrollMeTo(this.activeStep);
			this.visibleError = false;
			this.reloadLoop();
		} else {
			store.dispatch(PROPOSAL_INFO_ERROR, true);
			this.visibleError = true;
		}
	}
	public async nextStep(step: any) {
		if (!step.isValid) {
			store.dispatch(PROPOSAL_INFO_ERROR, true);
			this.visibleError = true;
			return;
		}

		const active = store.getters.getActiveProposalStep;

		if (!active) {
			this.visibleError = false;
			// all steps are valid
			// todo check if it's last step
			store.state.showSpinner = true;
			let time = new Date().getTime();
			await submitConsent();
			const PatchRes = await submitProposal();
			time = (new Date().getTime() - time) / 1000;
			this.submitTime = formatterDigit.format(time) + ' sek.';

			console.warn('TODO klk secure signicat url!!!');
			setTimeout(() => {
				//location.href = store.state.ProposalContext.signatureUrl;
				store.state.modal = this.testSignicatModal(PatchRes?.status === 200);
				store.state.showSpinner = false;
			}, 100);
			return;
		}

		this.activeStep = active;
		this.visibleError = false;
		this.scrollMeTo(this.activeStep);
		this.reloadLoop();
	}

	private testSignicatModal(patchRes) {
		const testReceiptUrl = `/receipt-page?quote=${store.state.ProposalContext.quote_id}&customer_no=${store.state.ProposalContext.customerNo}&signature=${store.state.ProposalContext.signatureId}`;
		// setup fail modal as default
		const modal: any = {};
		// modal.modalId = '12';
		modal.show = true;
		modal.id = 'proposal'; // never changes
		modal.track = false;
		modal.trackToken = undefined;
		modal.content = `
		<div style="background-color: ${patchRes ? 'lightgreen' : 'red'}; width: 100%; border-radius: 8px; 
		height: 44px;
		padding: 8px;">Brugerdata sendt til TIA Success: ${patchRes} (${this.submitTime})</div>
		<div style="display: flex; justify-content: center;">
		<ul class="text-left">
            <li>Underskriv hos Signicat</li>
            <li>Gå til kvittering</li>
            </ul></div>`;
		//modal.btnType = 'tel';
		modal.btnType = undefined; //'link';
		modal.btnStyle = 'btn-solid-secondary';
		modal.btnLabel = 'Gå til kvittering';
		modal.btnIcon = undefined;
		modal.btnAction = testReceiptUrl;
		modal.btnSecondStyle = 'btn-outline-secondary';
		modal.btnSecondLabel = 'Fortryd';
		modal.btnInlineLabel = undefined;
		modal.btnInlineAction = undefined;
		modal.btnInlinestyle = undefined; //'btn-solid-secondary';
		modal.title = 'Underskrift hos Signicat';
		modal.redirect = undefined; // '/'
		return modal;
	}

	public scrollMeTo(step: any) {
		if (!step?.id) {
			return;
		}
		setTimeout(() => {
			if (!isInView(`_${step.id}`)) {
				VueScrollTo.scrollTo(`#_${step.id}`, 300, { easing: 'ease-out', offset: -15 });
			}
			// Hack to Fix scrollbar missing
			//(document.body as HTMLElement).style.overflow = 'initial';
		}, 1200);
		//}, 1500);
	}
	private reloadLoop() {
		this.renderPage = !this.renderPage;
		this.renderPage = !this.renderPage;
	}
}
