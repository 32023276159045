import FieldValidator from '@/services/fieldValidatorService';
import { Options, Vue } from 'vue-class-component';

@Options({
	name: 'TextOnlyFieldComponent',
	components: {},
	props: {
		tooltip: String,
		htmlValue: String,
	},
})
export default class TextOnlyFieldComponent extends Vue {
	tooltip: string;
	htmlValue: string;

	public id: string;
	public value: string;
	public dynamicTooltip: boolean;

	public beforeMount() {
		this.id = '_' + Math.floor(Math.random() * 1000000);
		this.dynamicTooltip = this.tooltip && this.htmlValue?.toLocaleLowerCase()?.includes('{{tooltip}}');
		if (this.dynamicTooltip) {
			this.value = this.htmlValue.replace(/{{tooltip}}/gi, '<span class="tooltip-temp hide"/>');
		} else {
			this.value = this.htmlValue;
		}
	}

	public mounted() {
		if (this.dynamicTooltip) {
			const me = document.getElementById(this.id).querySelector('span.tooltip-temp');
			const pop = document.getElementById(this.id).querySelector('.dynamic-position');
			me.appendChild(pop);
			me.classList.toggle('hide');
		}
	}
}
