
	import { defineComponent } from 'vue';
	import { defaultConfig } from '@/cms/api/ApiConfig';
	import store from '@/store/store';
	import { runAtStart } from '@/startupRunners';

	export default defineComponent({
		data() {
			return {
				firstLoad: true,
			};
		},
		computed: {
			canonicalUrl() {
				return store.state.epiContent.model?.canonicalUrl;
			},
			themeName: () => {
				return store?.getters?.getThemeContext;
			},
		},
		methods: {
			isTouchDevice() {
				// TODO KLK
				const isTouchDevice =
					'ontouchstart' in window ||
					navigator.maxTouchPoints > 0 ||
					// eslint-disable-next-line
					(window as any).navigator.msMaxTouchPoints > 0;
				store.commit('setDeviceType', isTouchDevice);
				return isTouchDevice;
			},
			getScrollbarWidth() {
				if (this.isTouchDevice()) {
					return;
				}
				const scrollbarWidth = window.innerWidth - document.documentElement.clientWidth;
				document.documentElement.style.setProperty('--scrollbarWidth', `${scrollbarWidth}px`);
			},
			setCanonicalUrl() {
				const tag = document.querySelector('link[rel="canonical"]');
				if (tag) tag.setAttribute('href', this.canonicalUrl.url);
			},
		},
		mounted() {
			const element = document.getElementById('app');

			const domainUrl = element?.getAttribute('data-domain') ?? process.env.VUE_APP_BASEURL ?? '';

			const contentDeliveryApiUri =
				element?.getAttribute('data-contentdelivery-url') ?? process.env.VUE_APP_CONTENTDELIVERY_URL ?? '';
			// const language = element?.getAttribute('data-language') ?? "";
			//store.state.cmsDataModel.language = language;
			defaultConfig.apiUrl = domainUrl + contentDeliveryApiUri;
			defaultConfig.baseUrl = domainUrl;
			defaultConfig.selectAllProperties = true;
			defaultConfig.expandAllProperties = true;

			// TODO KLK - are these needed
			// this.$root['themeBrand'] = element?.getAttribute('data-theme-brand');
			// this.$root['themeBrandSimple'] = element?.getAttribute('data-theme-brand-simple');
			// this.$root['themeFavicon'] = element?.getAttribute('data-theme-favicon');

			/*
			 * If the `epieditmode` parameter is present we know we're
			 * in either edit- or preview mode and should include following scripts.
			 */
			// if (this.$route.query.epieditmode || document.location.search.includes('epieditmode')) {
			// 	const domainScript = document.createElement('script');
			// 	domainScript.innerHTML = "document.domain = 'localhost';";
			// 	document.body.appendChild(domainScript);

			// 	const communicationScript = document.createElement('script');
			// 	communicationScript.src = `${domainUrl}/episerver/cms/latest/clientresources/epi-cms/communicationinjector.js`;
			// 	document.body.appendChild(communicationScript);
			// }

			document.onreadystatechange = () => {
				// wait for DOM to render
				if (document.readyState === 'complete') setTimeout(this.getScrollbarWidth, 100);
			};

			runAtStart(this);
		},
		watch: {
			// meta() { // on page change
			//   this.setCanonicalUrl();
			// },
		},
	});
