import InputFieldComponent from '../inputFieldComponent/InputFieldComponent.vue';
import BulletListComponent from '../bulletListComponent/bulletListComponent.vue';
import ModalComponent from '../ModalComponent/ModalComponent.vue';
import { Options, Vue } from 'vue-class-component';
import store from '@/store/store';

@Options({
	name: 'SelectModalComponent',
	components: {
		InputFieldComponent,
		ModalComponent,
		BulletListComponent,
	},
	props: {
		name: String,
		id: String,
		label: String,
		preSelect: String,
		options: { type: Array, default: null },
		labels: { type: Array, default: null },
		overflow: { type: Boolean, default: false },
		disabled: { type: Boolean, default: false },
		selected: { type: Boolean, default: false },
		placeholder: String,
		visibleError: { type: Boolean, default: false },
		readonlyValue: String,
		errorMsgOverride: String,
	},
})
export default class SelectModalComponent extends Vue {
	name: string;
	id: string;
	label: string;
	preSelect: string;
	options?: Array<any>;
	labels?: Array<any>;
	overflow?: boolean; // text ellipsis
	disabled?: boolean;
	selected?: boolean; // Which checkmark to show
	placeholder: string;
	visibleError: boolean;
	readonlyValue: string;
	errorMsgOverride: string;

	public inputKey = 'inputKey';
	public inputValue: string = null;
	public readonlyValue_: string = null;

	public created() {
		try {
			if (!this.labels) {
				// labels and options are the same
				this.labels = this.options;
			}
			if (!this.options) {
				// labels and options are the same
				this.options = this.labels;
			}

			if (this.readonlyValue) {
				if (this.getLabel(this.readonlyValue)) {
					this.readonlyValue_ = this.getLabel(this.readonlyValue);
				}
			}
			if (this.preSelect) {
				if (this.getLabel(this.preSelect)) {
					this.inputValue = this.getLabel(this.preSelect);
				}
			}
		} catch (error) {
			console.error(error);
		}
	}

	private getLabel(value) {
		return this.labels[this.options.indexOf(value)];
	}

	public selectItem(evt): void {
		this.inputValue = evt.value;
		if (this.inputValue === undefined) {
			this.inputValue = this.labels[0];
		}

		setTimeout(
			() => {
				// wait for animation success
				this.$emit('SelectModalComponent:selected', {
					selectedValue: this.inputValue,
					id: this.id,
				});
				store.dispatch('toggleModal', {
					isActive: false,
					id: this.id,
				});

				this.inputKey += '1'; // refresh inputField
			},
			evt.instantly ? 0 : 600
		);
	}

	public open() {
		const input = document.getElementById(this.id + 1);
		input.blur();
		store.dispatch('toggleModal', {
			isActive: true,
			id: this.id,
		});
	}
}
