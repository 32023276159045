import { Log } from '@/appinsights/logging/log';
import store from '@/store/store';
import idleTimeout from 'idle-timeout';

/***** private methods *****************/
/**
 * @returns true if open in App, else false
 */
const isApp_ = (): boolean => {
	if (window.location.href.indexOf('client=app') > -1) {
		return true;
	}

	if ((<any>window).nsWebViewBridge !== undefined) {
		return true;
	}
	return false;
};

const isDevelop_ = () => {
	return (
		location.href.startsWith('https://localhost') ||
		location.href.startsWith('https://alpha-') ||
		location.href.startsWith('https://sit-') ||
		location.href.startsWith('https://prep-')
	);
};

const isLocalhost_ = () => {
	return location.href.startsWith('https://localhost');
};

/***** public *****************/
export const isExternalLink = (routePath): boolean => {
	return routePath && (routePath.startsWith('//') || routePath.toLocaleLowerCase().startsWith('https'));
};

/**
 * Sends a message to the app via gotMessage
 * @param message An object containing the message: { 'MAIN_TITLE': 'title'}
 *
 */
export const sendMessage = (message: {}) => {
	if (isApp) {
		(<any>window).nsWebViewBridge?.emit('gotMessage', message);
		if (!(<any>window).nsWebViewBridge) {
			Log.information('App_message', message);
		}
	}
};

export const browserIe11OrOlder = () => {
	let bool = false;
	if (navigator.userAgent.indexOf('MSIE') !== -1 || navigator.appVersion.indexOf('Trident/') > -1) {
		bool = true;
	}
	return bool;
};

export const handleIdleLogout = (logoutLocation: string, timeInMinutes?: number) => {
	let time = 1000 * 60 * 20;
	if (timeInMinutes) {
		time = 1000 * 60 * timeInMinutes;
	}
	idleTimeout(
		() => {
			window.location.href = logoutLocation;
		},
		{
			timeout: time,
			loop: true,
			element: <any>document,
		}
	);
};

/* eslint no-empty: ["error", { "allowEmptyCatch": true }] */
export const setPageMetaData = (model: any) => {
	try {
		if (!model) {
			return;
		}
		document.title = model.metaTitle || document.title;

		const metaData = [
			{ type: 'description', value: model.metaDescription || '', property: 'name' },
			{ type: 'keywords', value: model.metaKeywords || '', property: 'name' },
			{
				type: 'og:image',
				value: model.ogImage && model.ogImage.url ? model.ogImage.url : '',
				property: 'property',
			},
			{ type: 'og:description', value: model.ogDescription || '', property: 'property' },
			{ type: 'og:title', value: model.ogTitle || '', property: 'property' },
		];
		metaData.forEach((meta) => {
			const metaTag = document.querySelector(`meta[${meta.property}="${meta.type}"]`);

			if (!metaTag) {
				const tag = document.createElement('meta');
				tag.setAttribute(meta.property, meta.type);
				tag.setAttribute('content', meta.value);
				document.head.appendChild(tag);
			} else {
				metaTag.setAttribute('content', meta.value);
			}
		});
	} catch (e) {}
};

export const setVersion = () => {
	try {
		if (isDevelop) {
			const scripts = document.scripts;
			let stop = false;
			for (const item of scripts) {
				if (!stop && item.src.includes('js/app.js') && item.src.includes(location.host)) {
					const src = item.src.split('/');
					src.forEach((element) => {
						if (!Number.isNaN(parseFloat(element))) {
							store.state.uiVersion = 'ver. ' + element;
							stop = true;
						}
					});
				}
			}
		}
	} catch (e) {
		// Too bad
	}
};

export const isApp = isApp_();
export const isWeb = !isApp;
export const isDevelop = isDevelop_();
export const isLocalHost = isLocalhost_();
