import FieldValidator from '@/services/fieldValidatorService';
import { nextTick } from 'vue';
import { Options, Vue } from 'vue-class-component';
import { PROPOSAL_INFO_ERROR } from '@/store/modules/ProposalContext';
import store from '@/store/store';
import VueScrollTo from 'vue-scrollto';
import { isInView } from '@/services/uiService';

const fv: FieldValidator = new FieldValidator('default');
const showAllFields = true;
@Options({
	name: 'VerticalStepComponent',
	components: {},
	props: {
		step: Object,
		visibleError: { type: Boolean, default: false },
	},
})
export default class VerticalStepComponent extends Vue {
	public step: any;
	public visibleError: boolean;

	public renderPage = false;

	// TODO klk implement
	public addressValue: string = null;
	public searchAddress(evt) {
		console.warn('TODO IMPLEMENT address search ', evt?.matchText);
		if (evt?.matchText) {
			this.addressValue = evt?.matchText;
			return;
		}

		this.addressValue = undefined;
	}

	public async mounted() {
		try {
			this.setVisible(true, 1500);
			this.renderPage = true;

			store.subscribeAction((action, state) => {
				if (action.type === PROPOSAL_INFO_ERROR) {
					let firstError = false;
					this.step.inputFields.forEach((field, inx) => {
						if (!firstError && !field.isValid) {
							console.log('first error', field.id);
							firstError = true;
							if (!isInView(`${field.uiId}`)) {
								VueScrollTo.scrollTo(`#${field.uiId}`, 500, { easing: 'ease-out', offset: -20 });
								// Hack to Fix scrollbar missing
								//(document.body as HTMLElement).style.overflow = 'initial';
							}
						}
					});
				}
			});
		} catch (error) {
			console.error(error);
		}
	}

	private setVisible(focusNext = false, focusDelay = 300): void {
		//this.step.isValid = false;
		let focusId: string;
		let isAllValid = true;

		this.step.inputFields.forEach((field, inx) => {
			if (showAllFields) {
				// normal check - show all fields
				field.isVisible = true;
				if (isAllValid && !field.isValid) {
					focusId = field.uiId;
					// isAllValid = false;
				}
			} else {
				// normal check - show first unfilled field
				if (inx > 0) {
					if (this.step.inputFields[inx - 1].isValid && isAllValid) {
						// if previous field isValid
						field.isVisible = true;
						if (!field.isValid) {
							focusId = field.uiId;
						}
					} else {
						if (field.contentType !== 'TextOnlyField') {
							field.isVisible = false;
						}
					}
				} else {
					// first field is always visible
					field.isVisible = true;
					if (!field.isValid) {
						focusId = field.uiId;
					}
				}
			}

			// Dependency check
			// check if field is dependant on another field (and value)
			if (field.dependentId) {
				const other = this.step.inputFields.find((other) => other.id === field.dependentId);
				const otherValue = other?.value;
				// if (field.id === 'cancelExistingPolicyText') {
				//     console.log('dep', field.id, field.dependentId, otherValue);
				// }

				if (other?.isVisible && otherValue !== undefined && otherValue !== '') {
					if (!field.dependentValue || otherValue + '' === field.dependentValue) {
						field.isVisible = true;
						this.updateValid(field);
					} else {
						field.isVisible = false;
						this.updateValid(field);
						// set field to valid, since it shouldn't stop the flow if it's dependant value isn't set.
						field.isValid = true;
					}
				} else if (showAllFields) {
					field.isVisible = false;
					this.updateValid(field);
					// set field to valid, since it shouldn't stop the flow if it's dependant value isn't set.
					field.isValid = true;
				}
			}

			if (!field.isValid) {
				// if (field.readonly) {
				//     field.isValid = true;
				// } else {
				console.warn('field is not valid', field.id);
				isAllValid = false;
				// }
			}
		});

		this.step.isValid = isAllValid;

		if (focusNext && focusId) {
			setTimeout(() => {
				const input = document.getElementById(focusId);
				if (input) {
					document.getElementById(focusId).focus();
				}
			}, focusDelay);
		}
		// console.log('inputFields state', this.step.inputFields);
	}

	private updateValid(field) {
		switch (field.contentType) {
			case 'TextField':
				this.inputValidate(field, undefined);
				break;
			case 'RadioButtonField':
				this.clickValidate(field, { value: field.value });
				break;
			case 'DatePickerField':
				this.dateValidate(field, field.value);
				break;
			case 'CheckField':
				this.checkboxValidate(field, field.value);
				break;
			case 'TextSearchField':
				this.searchValidate(field, { matchText: field.value });
				break;
			case 'DropDownField':
				this.modalValidate(field, { selectedValue: field.labels[field.options.indexOf(field.value)] });
				break;
			default:
				return;
		}
	}
	// private log(log, ...args) {
	//     if (log) {
	//         console.log(args);
	//     }
	// }

	private inputValidate(field, evt) {
		if (!field.value || field?.value?.trim() === '') {
			field.isValid = false;
			return;
		}
		if (field.min !== 0 || field.max !== 0) {
			if (field.type === 'number') {
				const v = parseInt(field.value);
				field.isValid = v >= field.min && (v <= field.max || field.max === 0);
				return;
			}
			// Text
			field.isValid = field.value.length >= field.min && (field.max === 0 || field.value.length <= field.max);
			return;
		}
		// TODO KLK named validator
		field.isValid = fv.isValid(field.value);
	}

	public inputChange(field, evt) {
		this.inputValidate(field, evt);
		this.setVisible(false);
	}

	private clickValidate(field, evt) {
		if (fv.isValid(evt?.value)) {
			field.value = evt.value;
			field.isValid = true;
		} else {
			field.isValid = false;
		}
	}
	public radioClick(field, evt) {
		this.clickValidate(field, evt);
		this.setVisible(true);
	}
	public selectGroup(field, evt) {
		this.clickValidate(field, evt);
		this.setVisible(true);
	}

	private checkboxValidate(field, evt) {
		field.isValid = true; // always valid
		if (evt.checked === undefined) {
			evt.checked = false;
		}
		if (evt.checked === field.value) {
			return;
		}
		field.value = evt.checked;

		if (this.step.hasMasterChildCheckFields) {
			this.handleMasterChildren(field);
		}

		if (!field.value && field.isRequired) {
			field.isValid = false;
		}
	}

	private handleMasterChildren(field) {
		// special handling for checkfield children
		let updateUi = false;
		this.step.inputFields.forEach((f) => {
			if (f.masterId === field.id) {
				f.value = field.value;
				updateUi = true;
			}
		});
		if (updateUi) {
			this.renderPage = !this.renderPage;
			nextTick(() => {
				this.renderPage = !this.renderPage;
			});
		}
		// special handling for checkfield master
		if (field.masterId) {
			this.step.inputFields.forEach((f) => {
				if (f.id === field.masterId) {
					if (f.value) {
						f.value = false;
						this.renderPage = !this.renderPage;
						nextTick(() => {
							this.renderPage = !this.renderPage;
						});
					}
				}
			});
		}
	}

	public checkboxEvent(field, evt) {
		this.checkboxValidate(field, evt);
		this.setVisible(true);
	}

	private dateValidate(field, value) {
		if (value) {
			field.value = value;
			field.isValid = true;
		} else {
			field.value = undefined;
			field.isValid = false;
		}
	}
	public dateSelected(field, value) {
		console.warn('dateSelected TODO validation dates on exit', field.id, value);
		this.dateValidate(field, value);
		this.setVisible(true);
	}

	private searchValidate(field, evt) {
		// console.log('searchSelected', field, evt);
		if (evt?.matchText) {
			let inx = field.labels.indexOf(evt.matchText);
			if (inx > -1) {
				const id = field.options[inx];
				// console.log('found id', id, 'for', evt.matchText);
				//field.valueId = id;
				field.value = id; //evt.matchText;
				field.isValid = true;
				return;
			}
			inx = field.options.indexOf(evt.matchText);
			if (inx > -1) {
				const id = field.options[inx];
				// console.log('found id', id, 'for', evt.matchText);
				// field.valueId = id;
				field.value = evt.matchText;
				field.isValid = true;
				return;
			}
		}
		field.value = undefined;
		// field.valueId = undefined;
		field.isValid = false;
	}

	public searchSelected(field, evt) {
		this.searchValidate(field, evt);
		if (field.isValid) {
			this.setVisible(true);
		}
	}

	private modalValidate(field, evt) {
		// console.log('modalSelected', field, evt);
		// console.log('field', field);
		// console.log('evt.selectedValue', evt.selectedValue);
		// console.log('evt.selectedOption', evt.selectedOption);
		if (evt.selectedValue) {
			const option = field.options[field.labels.indexOf(evt.selectedValue)];
			// console.log('setting option', option);
			field.value = option;
			field.isValid = true;
			return;
		}

		field.value = undefined;
		field.isValid = false;
	}

	public modalSelected(field, evt) {
		this.modalValidate(field, evt);
		if (field.isValid) {
			this.setVisible(true);
		}
	}
}
