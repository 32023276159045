import { insuranceCompany } from '@/types/types';
import { ActionContext, Dispatch } from 'vuex';

export const ADD_PROPOSAL_STEPS = 'ADD_PROPOSAL_STEPS';
export const ADD_PROPOSAL_INFO = 'ADD_PROPOSAL_INFO';
export const PROPOSAL_INFO_ERROR = 'PROPOSAL_INFO_ERROR';
// export const UPDATE_PROPOSAL = 'UPDATE_PROPOSAL';

export interface ProposalContextState {
	proposalSteps: Map<string, any>;
	party: {
		address: string;
		customerNumber: string;
		name: string;
	};
	payment: {
		paymentMedthod: string;
		regno: string;
		account: string;
	};
	quote_id: string;
	customerNo: string;
	price: number;
	signatureUrl: string;
	signatureId: string;
	modal;
	originalRequest;
	insuranceCompanies: Array<insuranceCompany>;
}

const state: ProposalContextState = {
	proposalSteps: new Map(),
	party: undefined,
	payment: undefined,
	quote_id: undefined,
	customerNo: undefined,
	price: undefined,
	signatureUrl: undefined,
	signatureId: undefined,
	modal: undefined,
	originalRequest: undefined,
	insuranceCompanies: undefined,
};

const mutations = {
	[ADD_PROPOSAL_STEPS](state: any, proposalSteps: Array<any>) {
		// todo check om step eksistere
		proposalSteps.forEach((step) => {
			state.proposalSteps.set(step.id, step);
		});
	},
	[ADD_PROPOSAL_INFO](state: any, proposalInfo: any) {
		state.party = proposalInfo.party;
		// state.quote_id = proposalInfo.quote_id;
		state.payment = proposalInfo.payment;
		state.price = proposalInfo.price;
		state.signatureUrl = proposalInfo.signatureUrl;
	},
	[PROPOSAL_INFO_ERROR](state: any) {
		//subscription event
	},
};

const actions = {
	async [ADD_PROPOSAL_STEPS]({ commit, state, dispatch }: ActionContext<any, any>, proposalSteps: Array<any>) {
		commit(ADD_PROPOSAL_STEPS, proposalSteps);
		//dispatch(PROPOSAL_STEPS_LOADED); // notify subscribers
	},
	async [ADD_PROPOSAL_INFO]({ commit, state, dispatch }: ActionContext<any, any>, proposalInfo: any) {
		commit(ADD_PROPOSAL_INFO, proposalInfo);
		//dispatch(PROPOSAL_STEPS_LOADED); // notify subscribers
	},
	async [PROPOSAL_INFO_ERROR]({ commit, state, dispatch }: ActionContext<any, any>) {
		commit(PROPOSAL_INFO_ERROR);
		//dispatch(PROPOSAL_STEPS_LOADED); // notify subscribers
	},
};

export default {
	state,
	mutations,
	actions,
};
