export default {
	setEpiRouting({ commit }: { commit: any }, epiRouting: []) {
		commit('SET_EPI_ROUTING', epiRouting);
	},
	setUsabillaCount({ commit }: { commit: any }) {
		commit('SET_USABILLA_COUNT');
	},
	setUsabillaMounted({ commit }: { commit: any }) {
		commit('SET_USABILLA_MOUNTED');
	},
	toggleModal({ commit }: { commit: any }, modal: { id: string; isActive: boolean }) {
		commit('TOGGLE_MODAL', modal);
	},
};
