import { createStore } from 'vuex';

import Actions from './actions';
import Getters from './getters';
import Mutations from './mutations';
import { EpiContentState } from '@/store/modules/epiContent';
import epiContent from '@/store/modules/epiContent';
import ProposalContext, { ProposalContextState } from '@/store/modules/ProposalContext';
import authenticationContext, { AuthenticationState } from '@/store/modules/authenticationContext';

export type CmsRootState = {
	customer?: any;
	customerProducts?: any;
	isTouchDevice: boolean;
	epiRouting: [];
	usabilla: { mounted: number; count: number };
	modal: { id: string; show: boolean };
	epiContent?: EpiContentState;
	ProposalContext?: ProposalContextState;
	authenticationContext?: AuthenticationState;
	showSpinner: boolean;
	proxyUrl: string;
	mailOSSNoLogin: string;
	teamMailOSSNoLogin: string;
	// mailTeamUrl: string,
	uiVersion: string;
};

const rootState = {
	customer: undefined,
	customerProducts: undefined,
	isTouchDevice: false,
	epiRouting: [],
	usabilla: { count: 0, mounted: 0 },
	modal: { id: 'none', show: false },
	showSpinner: false,
	proxyUrl: '/api/mera/v1/openserviceproxy',
	mailOSSNoLogin: '/api/mera/v1/checkoutmail/sendossnotloggedin',
	teamMailOSSNoLogin: '/api/mera/v1/checkoutmail/sendteamnotloggedin',
	uiVersion: '',
} as CmsRootState;

export default createStore<CmsRootState>({
	state: rootState,
	mutations: Mutations,
	actions: Actions,
	getters: Getters,
	modules: {
		epiContent,
		ProposalContext,
		authenticationContext, // TODO KLK load user if logged in?
	},
});
