import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createBlock as _createBlock, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "input" }
const _hoisted_2 = ["for", "innerHTML"]
const _hoisted_3 = ["id", "type", "name", "placeholder", "data-t-input", "required", "value", "autocomplete"]
const _hoisted_4 = {
  key: 3,
  class: "flex input__field input__field--readonly"
}
const _hoisted_5 = ["innerHTML"]
const _hoisted_6 = { class: "text-left mb-3" }
const _hoisted_7 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PopoverComponent = _resolveComponent("PopoverComponent")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", _hoisted_1, [
      (_ctx.type !== 'hidden')
        ? (_openBlock(), _createElementBlock("label", {
            key: 0,
            for: _ctx.id,
            class: "input__label",
            innerHTML: _ctx.label
          }, null, 8, _hoisted_2))
        : _createCommentVNode("", true),
      (_ctx.type !== 'hidden' && _ctx.labelPopover)
        ? (_openBlock(), _createBlock(_component_PopoverComponent, {
            key: 1,
            type: "icon",
            class: "page-component__popover text-left",
            "button-class": "popover__icon popover__icon__questionmark",
            "inner-data": _ctx.labelPopover
          }, null, 8, ["inner-data"]))
        : _createCommentVNode("", true),
      (!_ctx.readonlyValue)
        ? (_openBlock(), _createElementBlock("div", {
            key: 2,
            class: _normalizeClass([{ 'input__field--valid': _ctx.isValid }, { 'input__field--error': _ctx.showError }, _ctx.inputClassOverride])
          }, [
            _createElementVNode("input", {
              id: _ctx.id,
              ref: _ctx.name,
              class: "input__field",
              type: _ctx.type,
              name: _ctx.name,
              placeholder: _ctx.placeholder,
              "data-t-input": _ctx.name,
              required: _ctx.required,
              value: _ctx.htmlInputValue,
              autocomplete: _ctx.autocomplete,
              onInput: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.change && _ctx.change(...args))),
              onBlur: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.change && _ctx.change(...args))),
              onFocus: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.change && _ctx.change(...args)))
            }, null, 40, _hoisted_3)
          ], 2))
        : (_openBlock(), _createElementBlock("div", _hoisted_4, [
            _createElementVNode("div", { innerHTML: _ctx.readonlyValue }, null, 8, _hoisted_5)
          ])),
      _createElementVNode("div", _hoisted_6, [
        (_ctx.showError)
          ? (_openBlock(), _createElementBlock("span", {
              key: 0,
              class: "input__field--error_message",
              innerHTML: _ctx.validationErrorFormatText
            }, null, 8, _hoisted_7))
          : _createCommentVNode("", true)
      ])
    ])
  ]))
}