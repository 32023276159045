import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router';
import CmsPageComponentSelector from '@/cms/components/infrastructure/pageComponentSelector/PageComponentSelector.vue';
import store from '@/store/store';
import { UPDATE_MODEL_BY_URL } from '@/store/modules/epiContent';
import TestPage from './views/digitalAccept/pages/TestPage.vue';
import { isDevelop } from './services/environmentService';

// const isInAppContext = AppService.isEnabled();
//const baseUrl = isInAppContext ? window.location.pathname : '';
// const baseUrl = '';

// const appCallbackPath = (to, from, next) => {

//     // console.log('appCallbackPath', to, from, next);

//   // if (AppService.isEnabled()) {
//   //   if (store.getters.getAppReplaceRoute) {
//   //     AppService.sendMessage({ "REPLACE_ROUTE_PATH": to.path });
//   //     store.dispatch('setAppReplaceRoute', false);
//   //   } else {
//   //     AppService.sendMessage({ "ROUTE_PATH":  to.path });
//   //   }
//   // }
//   next();
// };

const routes: Array<RouteRecordRaw> = [
	// {
	// 	name: 'page-component-selector-all',
	// 	path: '/:catchAll(.*)',
	// 	component: CmsPageComponentSelector,
	// },
	{
		name: 'page-component-selector',
		path: '/:pathMatch(.*)',
		component: CmsPageComponentSelector,
		// beforeEnter: appCallbackPath,
	},
];

if (isDevelop) {
	routes.push({
		name: 'TestPage',
		path: '/testPage',
		component: TestPage,
	});
}

const router = createRouter({
	//history: isInAppContext?createWebHashHistory():createWebHistory(),
	history: createWebHistory(),
	routes,
	// scrollBehavior(to, from, savedPosition) {
	//   console.log('scrollBehavior', savedPosition);

	//   // return to scrolled position using browser history back
	//   // somehow returning savedposition object using Vue's native scrolling not working
	//   setTimeout(() => {
	//     let position = { left: 0, top: 0 };
	//     if (savedPosition) position = { left: savedPosition.left, top: savedPosition.top }
	//       window.scrollTo(position.left, position.top)
	//   }, 300); // match page transition duration
	// }
});

/* eslint no-empty: ["error", { "allowEmptyCatch": true }] */
// Sends message to app and cancel navigation, must be before epiContent beforeEach!
// router.beforeEach((to, from, next) => {

//   // if (isInAppContext && to.query.appMessageKey && to.query.appMessageValue) {
//   //   //supports sending multiple messages to the app via query, appMessageKey and appMessageValue length and order must match
//   //   if (to.query.appMessageKey instanceof Array) {

//   //     for (var i = 0; i < to.query.appMessageKey.length; i++) {
//   //       const key = to.query.appMessageKey[i];
//   //       const value = to.query.appMessageValue[i];
//   //       const message = {};
//   //       message[key] = value;
//   //       AppService.sendMessage(message);
//   //     }
//   //   }
//   //   else {
//   //     const message = {};
//   //     message[to.query.appMessageKey] = to.query.appMessageValue;
//   //     AppService.sendMessage(message);
//   //   }

//   //   return next(false);
//   // }

//   next();
// });

router.beforeEach(async (to, from, next) => {
	// URL is updated by vue-route-sync, and when time traveling with the
	// debugger we don't want to trigger a model commit as the model is already
	// part of the store holding the url update.
	const currentModelUrl = store.state.epiContent.model?.canonicalUrl.path;

	await updateModelByUrlFromFullPath(to, next, currentModelUrl);
});

async function updateModelByUrlFromFullPath(to, next, currentModelUrl) {
	const fullPath = to.fullPath.replace('#', '');

	if (currentModelUrl !== fullPath) {
		await store.dispatch(UPDATE_MODEL_BY_URL, fullPath);
		//don't render next page before model has been updated from url
	}
	next();
}

export default router;
