export default {
	getEpiRouting: (state) => {
		return state.epiRouting;
	},

	getUsabillaState: (state) => {
		return state.usabilla;
	},

	getThemeContext: (state) => {
		const themeContext = state.epiContent?.model?.theme;
		return themeContext ? themeContext : 'almbrand';
	},

	/** Digital Accept */
	getActiveProposalStep: (state) => {
		let active = undefined;
		if (state.ProposalContext?.proposalSteps?.size > 0) {
			state.ProposalContext.proposalSteps.forEach((step /*, key*/) => {
				if (!active && !step.isValid) {
					active = step;
				}
			});
			return active;
		}
		console.warn('did not found activeProposalStep', state.ProposalContext);
		return undefined;
	},
	getIsPreviousProposalStepsValid: (state) => (id: string) => {
		let previousValid = true;
		let stop = false;
		if (state.ProposalContext?.proposalSteps?.size > 0) {
			state.ProposalContext.proposalSteps.forEach((step /*, key*/) => {
				if (!stop) {
					if (step.id !== id) {
						if (previousValid && !step.isValid) {
							previousValid = false;
							stop = true;
						}
					} else {
						stop = true;
					}
				}
			});
		}
		return previousValid;
	},
};
