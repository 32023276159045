import { Vue, Options } from 'vue-class-component';
import { mapState } from 'vuex';
import getComponentTypeForContent from '@/cms/api/contentComponentSelector';
import { setPageMetaData } from '@/services/environmentService';
import store from '@/store/store';

@Options({
	name: 'PageComponentSelector',
	computed: mapState<any>({
		model: (state) => state.epiContent.model,
		modelLoaded: (state) => state.epiContent.modelLoaded,
	}),
})
export default class PageComponentSelector extends Vue {
	modelLoaded: boolean;
	model: any;

	public version = null;

	public mounted(): void {
		setTimeout(() => {
			if (store.state.uiVersion) {
				this.version = store.state.uiVersion;
				console.log('Frontend version', this.version);
			}
		}, 3000);
	}

	getComponentTypeForPage(model) {
		return getComponentTypeForContent(model);
	}
}
