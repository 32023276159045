import { Options, Vue } from 'vue-class-component';
import PageTitleComponent from '@/sharedcomponents/pageComponents/pageTitle/PageTitleComponent.vue';
import { AcceptPage } from '@/cms/definitions/content-types';
import store from '@/store/store';
import CookieFooter from '@/sharedcomponents/pageComponents/cookieFooter/cookieFooter.vue';
import VerticalContainerComponent from './VerticalContainerComponent.vue';
import { handleIdleLogout, isDevelop } from '@/services/environmentService';

@Options({
	name: 'DigitalAcceptComponent',
	components: {
		PageTitleComponent,
		VerticalContainerComponent,
		// ContactComponent,
		CookieFooter,
	},
	props: {
		model: Object,
	},
})
export default class DigitalAcceptComponent extends Vue {
	public model: AcceptPage; // epi contentModel
	public usabillaBlock: any = null;
	public usabillaBlockLeaveIntent: any = null;
	public renderPage = false;
	public showFooterHack = false;
	public headline = '...';
	public isCodan = store?.getters?.getThemeContext === 'codan';
	public isDevelop = isDevelop;

	public beforeCreate() {
		handleIdleLogout('/logout', 30); // TODO KLK - remove data from session(?)
	}

	public async beforeMount() {
		store.state.showSpinner = true;

		this.handlePreTitle();
		window.addEventListener('resize', this.handlePreTitle);

		// TODO avoid timeout
		setTimeout(() => {
			this.renderPage = true;
			store.state.showSpinner = false;
		}, 500);

		setTimeout(() => {
			// TODO -> wait for proposal loaded
			this.showFooterHack = true;
		}, 2000);
	}

	public beforeUnmount() {
		// Todo klk proper Vue event
		window.removeEventListener('resize', this.handlePreTitle);
	}

	private handlePreTitle() {
		this.headline = this.model.headline;
		// screen.width < 768 ? this.preTitle = this.cms.shortTitle : this.preTitle = this.model.preTitle;
	}
}
