import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "page__title"
}
const _hoisted_2 = {
  key: 0,
  class: "h1-pretitle"
}
const _hoisted_3 = { class: "h1-underline h1-small" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_ctx.showTitle)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        (_ctx.preTitle)
          ? (_openBlock(), _createElementBlock("p", _hoisted_2, _toDisplayString(_ctx.preTitle), 1))
          : _createCommentVNode("", true),
        _createElementVNode("h1", _hoisted_3, _toDisplayString(_ctx.mainTitle), 1)
      ]))
    : _createCommentVNode("", true)
}