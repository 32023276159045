export default class TestConfig {
	public static getDogList() {
		return [
			'Affenpinscher',
			'Afghansk mynde',
			'Airedale terrier',
			'Akita / American Akita',
			'Alaskan malamute',
			'Alpenländische dachsbracke',
			'American Bulldog',
			'American Bulldog blanding',
			'Amerikansk Staffordshire Blanding',
			'Amerikansk cocker spaniel',
			'Amerikansk staffordshire terrier',
			'Amerikansk vandspaniel',
			'Anatolsk hyrdehund',
			'Appenzeller sennenhund',
			'Australian silky terrier',
			'Australsk Kelpie',
			'Australsk cattledog',
			'Australsk shepherd',
			'Australsk terrier',
			'Azawakh',
			'Balkan støver',
			'Barbet',
			'Basenji',
			'Basset artesien normand',
			'Basset bleu de gascogne',
			'Basset fauve de bretagne',
			'Basset hound',
			'Bayersk bjergschweisshund',
			'Beagle',
			'Beagle blanding',
			'Beagle harrier',
			'Bearded collie',
			'Beauceron',
			'Bedlington Terrier',
			'Berner sennen blanding',
			'Berner sennenhund',
			'Bichon Bolognese',
			'Bichon Frise',
			'Bichon Havanais',
			'Bichon blanding',
			'Black and tan Coonhound',
			'Blodhund',
			'Boer Boel',
			'Border Collie',
			'Border Collie Blanding',
			'Border Terrier',
			'Borzoi',
			'Boston Terrier',
			'Bouvier Des Flandres',
			'Boxer',
			'Boxer blanding',
			'Bracco Italiano',
			'Breton',
			'Breton blanding',
			'Briard',
			'Broholmer',
			'Broholmer blanding',
			'Bull terrier (bull terrier miniature)',
			'Bullmastiff',
			'Bullmastiff blanding',
			'Cairn terrier',
			'Cairn terrier blanding',
			'Cane corso italiano',
			'Cao de agua portugues',
			'Cavalier King Charles Blanding',
			'Cavalier king charles spaniel',
			'Centralasiatisk ovtcharka',
			'Cesky terrier',
			'Chesapeake bay retriever',
			'Chihuahua blanding',
			'Chihuahua, kort- / langhåret',
			'Chinese crested',
			'Chow chow',
			'Chow chow blanding',
			'Clumber spaniel',
			'Cocker spaniel',
			'Cocker spaniel blanding',
			'Collie',
			'Collie blanding',
			'Coton de tulear',
			'Coton de tulear blanding',
			'Curly coated retriever',
			'Dalmatiner',
			'Dalmatiner blanding',
			'Dandie dinmont terrier',
			'Dansk spids',
			'Dansk/svensk gårdhund',
			'Dansk/svensk gårdhund blanding',
			'Dobermann',
			'Dobermann blanding',
			'Dogo Canario',
			'Dogo argentino',
			'Dogue de bordeaux',
			'Drentsche patrijshond',
			'Drever/strellufstøver',
			'Dværgpinscher',
			'Dværgschanuzer',
			'Engelsk Springer Spaniel',
			'Engelsk Springer Spaniel blanding',
			'Engelsk bulldog',
			'Engelsk bulldog blanding',
			'Engelsk setter',
			'Engelsk toy terrier',
			'Entlebucher sennenhund',
			'Eurasier',
			'F.T. Springer spaniel',
			'Field spaniel',
			'Fila brasileiro',
			'Finsk hyrdehund (lapsk vallhund)',
			'Finsk lapphund',
			'Finsk spids',
			'Flat coated retriever',
			'Flat coated retriever blanding',
			'Fox terrier blanding',
			'Fox terrier,Glat- / ruhåret',
			'Foxhound, Amerikansk / engelsk',
			'Fransk bulldog',
			'Fransk bulldog blanding',
			'Franske jagthunde',
			'Galgo espanol',
			'Gammel dansk hønsehund',
			'Golden retriever',
			'Golden retriever blanding',
			'Gordon setter',
			'Grand Basset Griffon Vendeen',
			'Grand danois',
			'Grand danois blanding',
			'Gravhund, blanding',
			'Gravhund, kort-,lang-, ruhåret',
			'Greyhound',
			'Griffon (ruhåret)',
			'Griffon belge',
			'Griffon bruxellois',
			'Groenendael',
			'Grosser münsterländer',
			'Grosser schweizer sennenhund',
			'Grønlandshund',
			'Hannoveransk schweisshund',
			'Hellenikos Ichnilatis / græsk støver',
			'Hollandsk hyrdehund',
			'Hovawart',
			'Hovawart blanding',
			'Hvid schweizisk hyrdehund',
			'Hønsehund blanding',
			'Hønsehund, ru-/glathåret',
			'Irish glen of imaal terrier',
			'Irish softcoated wheaten terrier',
			'Irish water spaniel',
			'Irsk setter',
			'Irsk terrier',
			'Irsk ulvehund',
			'Islandsk fårehund',
			'Italiensk mynde',
			'Jack Russel blanding',
			'Jack Russell terrier',
			'Japanese',
			'Japansk spids',
			'Jämthund',
			'Kangal/Karabash',
			'Karelsk bjørnehund',
			'Kaukasisk ovtcharka',
			'Keeshond',
			'Kerry Blue Terrier',
			'King Charles Spaniel',
			'Kleiner münsterländer',
			'Kleiner münsterländer blanding',
			'Kleinspitz',
			'Kontinental Landseer',
			'Kooikerhondje',
			'Kromfohrländer',
			'Kuvasz',
			'Labradoodles',
			'Labrador retriever',
			'Labrador retriever blanding',
			'Laekenois',
			'Lagotto romagnolo',
			'Lakeland terrier',
			'Landseer',
			'Leonberger',
			'Lhasa apso',
			'Löwchen',
			'Malinois',
			'Malteser',
			'Manchester terrier',
			'Mastiff',
			'Mastiff blandning',
			'Mastino espanol',
			'Mastino napoletano',
			'Mexikansk Hårløs',
			'Mittelspitz',
			'Mops',
			'Mops blanding',
			'Newfoundland',
			'Newfoundland blanding',
			'Norfolk terrier',
			'Norrbottenspids',
			'Norsk buhund',
			'Norsk elghund',
			'Norsk lundehund',
			'Norwich terrier',
			'Nova Scotia duck tolling retriever',
			'Odderhund',
			'Old English Bulldog',
			'Old english sheepdog',
			'Old victorian bulldog',
			'Papillon',
			'Papillon blanding',
			'Parson Russell Terrier',
			'Patterdale Terrier',
			'Pekingeser',
			'Pekingeser blanding',
			'Perro De Pressa Canario',
			'Perro de agua espanol',
			'Peruviansk hårløs',
			'Petit Brabancon',
			'Petit basset griffon vendeen',
			'Phalene',
			'Pharaoh hound',
			'Picard',
			'Podenco',
			'Pointer',
			'Polski owczarek nizinny',
			'Polski owczarek podhalanski',
			'Pomeranian',
			'Pomeranian blanding',
			'Portugisisk gårdhund',
			'Prazsky Krysarik',
			'Puddel',
			'Puddel blanding',
			'Pudelpointer',
			'Puli',
			'Pumi',
			'Pyreneerhund',
			'Pyrenæisk hyrdehund',
			'Rhodesian ridgeback',
			'Riesenschnauzer',
			'Rottweiler',
			'Rottweiler blanding',
			'Russian Toy Terrier',
			'Saluki',
			'Samojed blanding',
			'Samojedhund',
			'Sankt bernhards blanding',
			'Sankt bernhardshund',
			'Sarplanina',
			'Schapendoes',
			'Schipperke',
			'Schnauzer',
			'Schnauzer blanding',
			'Schæferhund',
			'Schæferhund blanding',
			'Sealyham terrier',
			'Shar pei',
			'Shetland sheepdog',
			'Shiba',
			'Shih tzu',
			'Siberian Husky',
			'Silky terrier',
			'Skotsk hjortehund',
			'Skotsk terrier',
			'Sort Spids',
			'Sort russisk terrier',
			'Spansk gårdhund',
			'Spids-/Slædehunde blanding',
			'Spinone',
			'Stabyhoun',
			'Staffordshire Bull Terrier',
			'Staffordshire Bull Terrier blanding',
			'Sussex spaniel',
			'Tervueren',
			'Tervuerenblanding',
			'Thai ridgeback dog',
			'Tibetansk mastiff',
			'Tibetansk spaniel',
			'Tibetansk terrier',
			'Tibetansk terrier blanding',
			'Tjekkoslovakisk ulvehund',
			'Tornjak',
			'Tysk Brache',
			'Tysk Pinscher',
			'Tysk jagtterrier',
			'Tysk wachtelhund',
			'Vizsla',
			'Volpino italiano',
			'Västgötaspids',
			'Weimaraner',
			'Welsh corgi cardigan',
			'Welsh corgi pembroke',
			'Welsh springer spaniel',
			'Welsh terrier',
			'West highland white terrier',
			'West highland white terrier blanding',
			'Whippet',
			'Xolointzcuintle',
			'Yorkshire terrier',
			'Yorkshire terrier blanding',
			'Østrigsk pincher',
			'Øvrige',
		];
	}

	public static getDogListIds() {
		return [
			'4',
			'5',
			'6',
			'7',
			'8',
			'9',
			'10',
			'11',
			'13',
			'12',
			'14',
			'15',
			'16',
			'17',
			'18',
			'19',
			'20',
			'21',
			'22',
			'23',
			'24',
			'25',
			'26',
			'27',
			'28',
			'29',
			'30',
			'31',
			'32',
			'33',
			'34',
			'35',
			'36',
			'37',
			'38',
			'39',
			'41',
			'42',
			'43',
			'40',
			'44',
			'45',
			'46',
			'47',
			'48',
			'49',
			'50',
			'51',
			'52',
			'53',
			'54',
			'55',
			'56',
			'57',
			'58',
			'59',
			'60',
			'61',
			'62',
			'63',
			'64',
			'65',
			'66',
			'67',
			'68',
			'69',
			'70',
			'71',
			'72',
			'73',
			'74',
			'75',
			'76',
			'77',
			'78',
			'79',
			'80',
			'81',
			'82',
			'83',
			'84',
			'85',
			'86',
			'87',
			'88',
			'89',
			'90',
			'91',
			'92',
			'93',
			'95',
			'94',
			'96',
			'97',
			'98',
			'99',
			'100',
			'104',
			'105',
			'101',
			'102',
			'103',
			'106',
			'107',
			'108',
			'109',
			'110',
			'111',
			'112',
			'113',
			'114',
			'115',
			'116',
			'117',
			'118',
			'119',
			'120',
			'121',
			'122',
			'123',
			'124',
			'125',
			'126',
			'127',
			'128',
			'129',
			'130',
			'131',
			'132',
			'133',
			'134',
			'135',
			'136',
			'137',
			'138',
			'139',
			'140',
			'141',
			'142',
			'143',
			'144',
			'145',
			'146',
			'147',
			'148',
			'149',
			'150',
			'151',
			'152',
			'153',
			'154',
			'155',
			'156',
			'157',
			'158',
			'159',
			'160',
			'161',
			'162',
			'163',
			'164',
			'165',
			'166',
			'167',
			'168',
			'169',
			'170',
			'171',
			'172',
			'173',
			'174',
			'175',
			'176',
			'177',
			'178',
			'179',
			'180',
			'181',
			'182',
			'183',
			'184',
			'185',
			'186',
			'187',
			'188',
			'189',
			'190',
			'191',
			'192',
			'193',
			'194',
			'195',
			'196',
			'197',
			'198',
			'199',
			'200',
			'201',
			'202',
			'203',
			'204',
			'205',
			'206',
			'207',
			'208',
			'209',
			'210',
			'211',
			'212',
			'213',
			'214',
			'216',
			'215',
			'217',
			'219',
			'218',
			'220',
			'221',
			'222',
			'223',
			'224',
			'225',
			'226',
			'227',
			'228',
			'229',
			'230',
			'231',
			'232',
			'233',
			'234',
			'235',
			'236',
			'237',
			'238',
			'239',
			'240',
			'241',
			'242',
			'243',
			'244',
			'245',
			'246',
			'247',
			'248',
			'249',
			'250',
			'251',
			'252',
			'253',
			'254',
			'255',
			'256',
			'257',
			'258',
			'259',
			'298',
			'260',
			'261',
			'262',
			'264',
			'263',
			'265',
			'266',
			'267',
			'268',
			'269',
			'270',
			'271',
			'272',
			'273',
			'274',
			'275',
			'276',
			'277',
			'278',
			'279',
			'280',
			'281',
			'283',
			'282',
			'284',
			'285',
			'286',
			'287',
			'288',
			'289',
			'290',
			'291',
			'292',
			'293',
			'294',
			'295',
			'296',
			'297',
			'1',
			'2',
			'3',
		];
	}

	// public static getInsuranceCompanies() {
	//     return ["Agria Dyreforsikring","AIG","Alka","Allianz","Alm. Brand A/S","Alpha Insurance","Aros Forsikring","Axa Danmark","Bauta Forsikring A/S","Bornholms Brandforsikring","Byg Forsikringsservice A/S","Chubb European Group Limited","Codan Forsikring A/S","Concordia Forsikring A/S","Coop for Topdanmark Forsikring","DFA for ETU Forsikring","ETU Forsikring A/S","Euro Insurance Ltd.","Europæiske Rejseforsikring","First for QIC Europe Ltd.","Focus Forsikringsagtentur for INSR","Fokus Forsikring A/S","Gartnernes Forsikring","Gefion Insurance A/S","Gf-Forsikring A/S","Gjensidige Forsikring","Global for Insr Insurance Group","Global for Sønderjysk Forsikring","Gouda for Gjensidige Forsikring","Greenval Insurance Company Ltd.","HD Forsikring Assurance Agentur","HDI Danmark","Hf-Forsikring G/S","Himmerland Forsikring Gs","If P&C Insurance v. Watercircles","If Skadesforsikring","Insr Insurance Group ASA","Købstædernes Alm.Brand","LB-PFA for Lærerstandens Brandfors.","LLOYD'S/QBE","Lloyds 1886 v. QBE Danmark A/S","Lloyds v/First","Lloyds v/Indigo Underwriters","Lloyds XL Catlin v/Baltic Finance","Lokal Forsikring","Lærerstandens Brandforsikring G","Mondo for Insur Insurance Group","Morsø Forsikring","Mølholm for Gjensidige Forsikring","Nem Forsikring A/S","Nykredit for Gjensidige Forsikring","Nærsikring","Pantaenius","PenSam Forsikring A/S","Popermo G/S","Privatsikring A/S","Prosam forsikringsagentur for Insr","Protector","QBE DANMARK (Belgien)","Qudos Insurance A/S","Risk Point A/S","Runa Forsikring A/S","Stevns Brand G/S","Storstrøms Forsikring G/S","Søassuranceforeningen Ærø G/S","Søassurancen Danmark","Sønderjysk Forsikring A/S","Thisted Amt - Forsikring","Topdanmark Forsikring A/S","Topdanmark Livsforsikring","Trafik","Tryg Forsikring","TRYGD","Tryggingarfelagid Føroyar","Vejle Brand Af 1841","Vendsyssel Forsikring G/S","Vestjylland G/S","Viking for ETU Forsikring","Watercircles Forsikring ASA","Wintherthur International","Zürich Danmark"];
	// }

	// public static getInsuranceCompaniesIds() {
	//     return ["831","15","1","830","73","105","6","13","77","87","804","89","70","93","835","123","101","61","816","133","125","54","88","116","64","106","124","137","827","112","837","119","75","68","111","32","115","95","136","19","107","102","118","832","86","33","834","811","836","35","96","817","803","109","79","51","127","110","135","113","818","30","805","4","820","833","80","71","34","829","55","8","823","826","92","72","76","122","120","63","104"];
	// }

	public static getPolicyProposalUrl() {
		// return '/policy/approval/22832506101';
		// https://sit-accept.almbrand.dk/proposition-page?quote=1234&customer_no=9690254254&signature=2ba33dca46f567266c10f9774962952f158c20d763cedb92d21dabca50c935bd
		return '/quote-accept/quote/1234?customer_no=9690254254&signature=048b5a06172755bd4b33f139b99dc663e3d2ebee5f6440cba3596f4fb6f54458';
	}

	public static getReceiptHardCoded() {
		//return this.getPolicyProposalHardCoded();
		return this.getPolicyProposalHardCoded2();
	}

	public static getPolicyProposalHardCoded() {
		return {
			data: {
				quote_id: '22832506101',
				price: 5500, // yearly
				fees: 124,
				discount: 557, // missing from API
				discountCampaign: 'Sydbank aftale', // missing from API
				discountCampaignValue: 'Rabatten er fratrukket', // missing from API
				signatureUrl: 'https://www.signicat.com?testparam=true', // missing from API
				payment: {
					paymentMethod: 'PBS',
					regno: '123',
					account: '1234567890',
				},
				party: {
					name: 'Dennis Rasmus Bo Test',
					address: 'Blomstervænget 11, Øm, 4000 Roskilde',
					customerNumber: '9689389871',
				},
				policies: [
					{
						id: '123b',
						type: 'CAR',
						price: 3250,
						cover_start_date: '2023-12-01', // TODO FIX API - is on product level
						payment_frequency: 1, // TODO FIX API  - should be for all products
						fee: 45, // missing from API
						description: 'Suzuki Baleno 1x PY', // missing from API
						fields: [
							{
								id: 'policy_valid_from', // TODO FIX API  is now cover_start_date - move here
								value: '2024-01-13',
								read_only: true,
							},
							{
								id: 'primary_user', // MISSING API
								value: 'Dennis Rasmus Bo Test Primary user',
								read_only: true,
							},
							{
								id: 'license_plate', // OK
								// "required": false,
								read_only: true,
								value: 'AS88523',
							},
							{
								id: 'yearly_mileage', // Missing API
								value: '15000',
								// "value": "Op til 5.000 km.",
								required: true,
								read_only: false,
							},
							{
								id: 'years_driving', // OK
								required: true,
								read_only: false,
								value: '4',
							},
							{
								id: 'mileage', // OK
								required: true,
								read_only: false,
								value: '2500',
							},
							{
								id: 'mileage_date', // OK
								required: false,
								read_only: false,
								value: '2023-10-10T00:00:00.000Z',
							},
							{
								id: 'claims', // OK
								required: false,
								read_only: false,
								value: '0',
							},
							//,
							// {
							//     "id": "previous_company_id", // moved from termination
							//     "required": true,
							//     "read_only": true,
							//     "value": "00001"
							// },
							// {
							//     "id": "previous_policy_id",// moved from termination
							//     "required": true,
							//     "read_only": false,
							//     "value": "1234567890"
							// },
							// {
							//     "id": "transfer_code",// moved from termination
							//     "required": true,
							//     "read_only": true,
							//     "value": "12345"
							// },
							// {
							//     "id": "takeover_date",// moved from termination
							//     "required": true,
							//     "read_only": true,
							//     "value": "2024-01-14"
							// }
						],
						termination: {
							// "previousCompanyId": "Alka",
							// "previousPolicyId": "2349493928pb",
							// "transferCode": "1"
							previous_company_id: '00001',
							previous_policy_id: '1234567890',
							transfer_code: '12345',
							takeover_date: '2023-12-14', // FIX API - is perhaps already in cover_start_date/policy_valid_from
						},
					},
					// {
					//     "id": "123b1",
					//     "type": "INDBO",
					//     "price": 2250,// yearly
					//     "fee": 55,// missing from API
					//     "description": "Bellisvej 13, 3480 Fredensborg", // missing from API
					//     "fields": [
					//         {
					//             "id": "policy_valid_from",
					//             "value": "01-06-2023",
					//             "readOnly": true,
					//         },
					//         // {
					//         //     "id": "address",
					//         //     "value": "Bellisvej 13, 3480 Fredensborg",
					//         //     "readOnly": true,
					//         // },
					//         // {
					//         //     "id": "persons",
					//         //     "value": "3 Personer",
					//         //     "readOnly": true,
					//         // },
					//         // {
					//         //     "id": "sum",
					//         //     "value": "839.000 kr.",
					//         //     "readOnly": true,
					//         // },
					//     ],
					//     // "termination": {
					//     //     "previousCompanyId": "Alka",
					//     //     "previousPolicyId": "2349493928pb",
					//     //     "transferCode": "1"
					//     // }
					// },
					// {
					//     "id": "123b2",
					//     "type": "ULYKKE",
					//     "price": 1345,//  yearly
					//     "fee": 24, // missing from API
					//     "description": "Bygningssnedker", // missing from API
					//     "fields": [
					//         {
					//             "id": "job",
					//             "value": "Bygningssnedker",
					//             "readOnly": true,
					//         },
					//         {
					//             "id": "persons",
					//             "value": "3 Personer",
					//             "readOnly": true,
					//         },
					//         {
					//             "id": "sum",
					//             "value": "839.000 kr.",
					//             "readOnly": true,
					//         },
					//     ],
					//     // "termination": {
					//     //     "previousCompanyId": "Alka",
					//     //     "previousPolicyId": "2349493928pb",
					//     //     "transferCode": "1"
					//     // }
					// },
					// {
					//     "id": "123",
					//     "type": "HEST",
					//     "price": 1000,// yearly
					//     "fee": 45, // missing from API
					//     "description": "Dansk flodhest", // missing from API
					//     "fields": [
					//         {
					//             "id": "race",
					//             "readOnly": false,
					//         },
					//         {
					//             "id": "koen",
					//             "readOnly": false,
					//         },
					//     ],
					//     "termination": {
					//         "previousCompanyId": "Alka",
					//         "previousPolicyId": "2349493928pb",
					//         "transferCode": "1"
					//     }
					// },
					// {
					//     "id": "124",
					//     "type": "HEST",
					//     "price": 500,// yearly
					//     "fee": 25,// missing from API
					//     "description": "Araber", // missing from API
					//     "fields": [
					//         {
					//             "id": "race",
					//             "readOnly": false,
					//         },
					//         {
					//             "id": "koen",
					//             "readOnly": false,
					//         },
					//     ],
					// },
					// {
					//     "id": "189786492",
					//     "type": "HUND",
					//     "price": 2000,
					//     "fee": 108, // missing from API
					//     "description": "Beagle", // missing from API
					//     "fields": [
					//         {
					//             "id": "race",
					//             "readOnly": false,
					//             "value": "Beagle"
					//         },
					//         {
					//             "id": "koen",
					//             "readOnly": true,
					//             "value": "H"
					//         },
					//         {
					//             "id": "navn",
					//             "readOnly": true,
					//             "value": "King"
					//         }
					//     ],
					//     "termination": {
					//         "previousCompanyId": "Bauta Forsikring A/S",
					//         "previousPolicyId": "1AB2",
					//         "transferCode": "77"
					//     }
					// },
					// {
					//     "id": "189786484",
					//     "type": "HUND",
					//     "price": 945,
					//     "fee": 12, // missing from API
					//     "description": "Alpenländische dachsbracke", // missing from API
					//     "fields": [
					//         {
					//             "id": "race",
					//             "readOnly": true,
					//             "value": "Alpenländische dachsbracke"
					//         },
					//         {
					//             "id": "koen",
					//             "readOnly": true,
					//             "value": "T"
					//         },
					//         {
					//             "id": "navn",
					//             "readOnly": false,
					//             "value": "Fido"
					//         }
					//     ]
					// },
				],
			},
		};
	}
	public static getPolicyProposalHardCoded2() {
		return {
			data: {
				quote_id: '1234',
				price: 7152.21,
				fees: null,
				discount: 0,
				discount_campaign: 'Sydbank aftale',
				discount_campaign_value: 'Rabatten er fratrukket',
				party: {
					name: 'Primary Nasa',
					address: 'Robert Jacobsens Vej 100 1. TH10, 2300 København S',
					customer_no: 9690977599,
					customer_type: 'PRIMARY',
					payment: {
						payment_method: 'PBS',
						reg_no: '1234',
						account: '1234567890',
						payment_frequency: 1,
					},
				},
				policies: [
					{
						id: '207472093',
						type: 'CAR',
						price: 7152.21,
						fees: null,
						description: 'NA12345 Suzuki Alto',
						cover_start_date: '2024-04-01',
						fields: [
							{
								id: 'license_plate',
								required: false,
								read_only: false,
								value: 'NA12345',
							},
							{
								id: 'mileage_date',
								required: false,
								read_only: false,
								value: '2024-04-01T00:00:00.000Z',
							},
							{
								id: 'claims',
								required: false,
								read_only: false,
								value: '0',
							},
							{
								id: 'years_driving',
								required: false,
								read_only: false,
								value: '6',
							},
							{
								id: 'mileage',
								required: false,
								read_only: false,
								value: '15000',
							},
						],
						termination: {
							previous_company_id: '00001',
							previous_policy_id: '1515151515',
							takeover_date: '2024-03-30',
						},
						payment: {
							payment_method: 'PBS',
							reg_no: '1234',
							account: '1234567890',
							payment_frequency: 1,
						},
					},
				],
			},
		};
	}

	public static getPolicyProposalHardCoded3() {
		return {
			status: 'TEST_DATA',
			data: {
				quote_id: '0Q08E000003KoqjSAC',
				price: 6078,
				fees: 51,
				discount: 0,
				discount_campaign: 'Sydbank aftale',
				discount_campaign_value: 'Rabatten er fratrukket',
				party: {
					name: 'Michal Skoropa',
					address: 'Københavnsvej 273, 4000 Roskilde',
					customer_no: 9641500711,
					customer_type: 'PRIMARY',
					payment: {
						payment_method: 'PBS',
						reg_no: '1234',
						account: '1234567890',
						payment_frequency: 1,
					},
				},
				policies: [
					{
						id: '199768239',
						type: 'CAR',
						price: 6078,
						fees: 51,
						description: ' Nissan Patrol',
						cover_start_date: '2023-07-01',
						policy_object: {
							insurance_type: 'CAR',
							license_plate: 'mocked',
							mileage: 1231231,
							mileage_date: '2024-04-04',
							years_driving: 5,
							claims: 1,
						},
						termination: {
							previous_company_id: '00001',
							previous_policy_id: '123123123',
							takeover_date: '2024-04-30',
						},
						payment: {
							payment_method: 'PBS',
							reg_no: '1234',
							account: '1234567890',
							payment_frequency: 1,
						},
					},
				],
			},
		};
	}

	public static getPolicyConsentHardCoded() {
		return {
			status: 'TEST_DATA',
			data: {
				consents: [
					{
						consent_type: 'PROMOTIONPHONE',
						is_accepted: false,
						consent_text: 'Mocked phone consent',
					},
					{
						consent_type: 'PROMOTIONLETTER',
						is_accepted: true,
						consent_text: 'Mocked letter consent',
					},
					{
						consent_type: 'PROMOTIONMITALMBRAND',
						is_accepted: false,
						consent_text: 'Mocked Mitalmbrand consent',
					},
					{
						consent_type: 'PROMOTIONSMS',
						is_accepted: true,
						consent_text: 'Mocked SMS consent',
					},
					{
						consent_type: 'PROMOTIONEMAIL',
						is_accepted: true,
						consent_text: 'Mocked email consent',
					},
				],
			},
		};
	}
}
