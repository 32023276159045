import store from '@/store/store';
import { CheckField, Consent, ConsentResponse } from '@/types/types';
import { getConsent } from './apiService';
import { isDevelop } from './environmentService';

export const updateConsentFromApi = async (): Promise<void> => {
	const inputFields = store.state.ProposalContext.proposalSteps.get('consentBlock')?.inputFields;
	const consentResponse: { data: ConsentResponse; status: any } = await getConsent();

	if (consentResponse?.status === 'TEST_DATA') {
		console.error('fail consent TEST_DATA LOADED');
	} else if (consentResponse?.status !== 200) {
		console.error('fail consent');
		// TODO ERROR
		return;
	}
	const consentData: ConsentResponse = consentResponse.data;

	consentData.consents.forEach((consent: Consent) => {
		const field = inputFields.find((field: CheckField) => field.id === consent.consent_type);
		if (field) {
			field.value = consent.is_accepted;
			if (consent.consent_text && consent.consent_text.trim() !== '') {
				field.postLabel = isDevelop
					? `<b>[${field.postLabel}]</b> ${consent.consent_text}`
					: consent.consent_text;
			}
		} else {
			console.error('Field', consent.consent_type, 'is not present in CMS');
		}
	});
};

export const createConsentBody = (): { consents: Array<Consent> } => {
	const inputFields = store.state.ProposalContext.proposalSteps.get('consentBlock')?.inputFields;

	const consents: Array<Consent> = [];
	const consentBody = { consents };

	inputFields.forEach((field: CheckField) => {
		if (field?.masterId) {
			const consent: Consent = {
				consent_type: field.id,
				is_accepted: field?.value ? (field?.value as boolean) : false,
			};
			consents.push(consent);
		}
	});
	return consentBody;
};
