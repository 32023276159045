import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = ["target"]
const _hoisted_2 = { key: 0 }
const _hoisted_3 = ["src"]
const _hoisted_4 = ["type"]
const _hoisted_5 = { key: 0 }
const _hoisted_6 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_ctx.btnType === 'tel' || 'mailto')
    ? (_openBlock(), _createElementBlock("a", {
        key: 0,
        ref: _ctx.name + _ctx.id,
        class: _normalizeClass([_ctx.btnClass || 'btn']),
        target: _ctx.btnType === 'mailto' ? '_blank' : undefined,
        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.clicked()))
      }, [
        (_ctx.icon)
          ? (_openBlock(), _createElementBlock("span", _hoisted_2, [
              _createElementVNode("img", {
                src: _ctx.icon,
                class: "btn-icon-left"
              }, null, 8, _hoisted_3)
            ]))
          : _createCommentVNode("", true),
        _createElementVNode("span", null, _toDisplayString(_ctx.label), 1)
      ], 10, _hoisted_1))
    : (_openBlock(), _createElementBlock("button", {
        key: 1,
        ref: _ctx.name + _ctx.id,
        type: _ctx.btnType || 'button',
        class: _normalizeClass([_ctx.btnClass || 'btn']),
        onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.clicked()))
      }, [
        (_ctx.icon)
          ? (_openBlock(), _createElementBlock("span", _hoisted_5, [
              _createElementVNode("img", {
                src: _ctx.icon,
                class: "btn-icon-left"
              }, null, 8, _hoisted_6)
            ]))
          : _createCommentVNode("", true),
        _createElementVNode("span", null, _toDisplayString(_ctx.label), 1)
      ], 10, _hoisted_4))
}