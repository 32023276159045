import { format, parse } from 'date-fns';

export const formatter: Intl.NumberFormat = Intl.NumberFormat('da-DK', {
	minimumFractionDigits: 0,
	maximumFractionDigits: 0,
} as any);

export const formatterDigit: Intl.NumberFormat = Intl.NumberFormat('da-DK', {
	minimumFractionDigits: 0,
	maximumFractionDigits: 2,
} as any);

const daDateFormat = 'dd-MM-yyyy';
const enDateFormat = 'yyyy-MM-dd';

export const formatDateString = (dateString: string, toDanish: boolean): string => {
	try {
		const now = new Date();
		const dkDate = parse(dateString, toDanish ? enDateFormat : daDateFormat, now);
		const v = format(dkDate, toDanish ? daDateFormat : enDateFormat);
		return v;
	} catch (error) {
		return dateString;
	}
};
