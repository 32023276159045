import { DigitalAcceptResponse, PaymentResponse, PolicyResponse } from '@/types/types';
import store from '@/store/store';
import { formatDateString } from './FormatService';

export const createDigitalAcceptSubmitRequest = () => {
	// console.log('store.state.ProposalContext.originalRequest', store.state.ProposalContext.originalRequest.policies);
	//const store.state.ProposalContext.proposalSteps.get
	// console.log('store.state.ProposalContext.proposalSteps', store.state.ProposalContext.proposalSteps);
	const paymentBlockFields = store.state.ProposalContext.proposalSteps.get('paymentBlock')?.inputFields;
	// console.log('store.state.ProposalContext', store.state.ProposalContext);

	const frequency: string = getFieldValue(paymentBlockFields, 'payment_frequency');

	const paymentResponse: PaymentResponse = {
		payment_method: frequency === '1' ? 'PBS' : 'Konto',
		reg_no: parseInt(getFieldValue(paymentBlockFields, 'reg_no')),
		account: parseInt(getFieldValue(paymentBlockFields, 'account')),
		payment_frequency: parseInt(frequency),
	};

	const policies: Array<PolicyResponse> = [];

	const policyIds = store.state.ProposalContext.originalRequest.policies.map((policy) => policy.id);
	policyIds.forEach((policyId) => {
		const policy = store.state.ProposalContext.proposalSteps.get(policyId);

		const fields = policy.inputFields;
		const policyResponse: PolicyResponse = {
			id: policyId,
			cover_start_date: getFieldValueDate(fields, 'cover_start_date'),
			update_insurance_object: {
				insurance_type: policy.type.toUpperCase(),
				mileage_date: getFieldValueDate(fields, 'mileage_date'),
				mileage: parseInt(getFieldValueDate(fields, 'mileage')),
			},
			// why is payment block here ??????
			payment: paymentResponse,
			termination: {
				previous_company_id: getFieldValue(fields, 'previous_company_id'),
				previous_policy_id: getFieldValue(fields, 'previous_policy_id'),
				transfer_code: 12345, // TODO What is this ????
				takeover_date: getFieldValueDate(fields, 'takeover_date'),
			},
		};
		policies.push(policyResponse);
	});

	const digitalAcceptResponse: DigitalAcceptResponse = {
		policies,
		party: {
			payment: paymentResponse,
		},
	};

	// console.log('digitalAcceptResponse', digitalAcceptResponse);
	return digitalAcceptResponse;
};

const getFieldValue = (fields: Array<any>, fieldId: string) => {
	return fields.find((field) => field.id === fieldId)?.value;
};

const getFieldValueDate = (fields: Array<any>, fieldId: string) => {
	return formatDateString(fields.find((field) => field.id === fieldId)?.value, false);
};
