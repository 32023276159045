export async function runAtStart(parent: any) {
	// TODO KLK - is this still working
	// window.addEventListener('load', function () {
	// 	if (window.fetch && (window as any).usabilla) {
	// 		(window as any).usabilla('setEventCallback', function (category, action, label, value, userData) {
	// 			if (action === 'In-Page:Success') {
	// 				//label = comment
	// 				//value = 1-5 of score
	// 				//userData = Responses provided by user
	// 				fetch('/api/profiles/event', {
	// 					method: 'POST',
	// 					headers: { 'Content-Type': 'application/json;charset=utf-8' },
	// 					credentials: 'same-origin',
	// 					body: JSON.stringify({
	// 						eventType: 'UsabillaFeedback',
	// 						payload: { comment: label, score: value, userData: userData },
	// 						userInfo: {},
	// 						value: value,
	// 						PageUri: window.location.href,
	// 						PageTitle: document.title,
	// 					}),
	// 				});
	// 			}
	// 		});
	// 	}
	// });
}
