import { nextTick } from 'vue';
import { Options, Vue } from 'vue-class-component';
import Autocomplete from 'vue3-autocomplete';
import 'vue3-autocomplete/dist/vue3-autocomplete.css';

@Options({
	name: 'AutocompleteComponent',
	components: {
		Autocomplete,
	},
	props: {
		debounce: { type: Number, default: 50 },
		readonlyValue: { type: String, default: null },
		search: Function,
		handleSubmit: Function,
		blur: Function,
		focus: Function,
		autocompleteId: { type: String, default: '_' + Math.random() },
		results: Array,
		placeholder: String,
		acRef: String,
		displayItem: Function,
		autocompleteNative: String,
		max: { type: Number, default: 10 },
	},
})
export default class AutocompleteComponent extends Vue {
	debounce: number;
	readonlyValue: string;
	search: Function;
	handleSubmit: Function;
	blur: Function;
	focus: Function;
	autocompleteId: string;
	results: Array<any>;
	placeholder: string;
	acRef: string;
	displayItem: Function;
	autocompleteNative: String; // undefined or "off"
	max: number;

	private autoCompleteContainer: HTMLElement;
	private input: HTMLInputElement;
	private debounceEvents: number;
	private interval;

	public mounted() {
		if (this.readonlyValue) {
			return;
		}
		this.debounceEvents = this.debounce * 3;

		nextTick(() => {
			if (this.$refs[this.acRef]) {
				this.$emit('autoCompleteField', this.$refs[this.acRef]);
				this.input = document.getElementById(this.autocompleteId) as HTMLInputElement;
				this.autoCompleteContainer = this.input.parentElement;
				if (this.autoCompleteContainer) {
					this.autoCompleteContainer.addEventListener('mouseover', this.hoverHandler);
					this.autoCompleteContainer.addEventListener('keyup', this.keyHandler);
					this.input.addEventListener('focus', this.tryFindFirstElem);
				}
			}
		});
	}

	public beforeUnmount() {
		if (this.autoCompleteContainer) {
			this.autoCompleteContainer.removeEventListener('mouseover', this.hoverHandler);
			this.autoCompleteContainer.removeEventListener('keyup', this.keyHandler);
			this.input.removeEventListener('focus', this.tryFindFirstElem);
		}
	}

	public forceSearch() {
		this.input.blur();
		this.input.focus();
	}

	public hoverHandler(evt) {
		if (this.results?.length > 0) {
			const hasSelected = this.autoCompleteContainer.querySelector('.vue3-results-item.selected');
			if (hasSelected) {
				hasSelected.classList.remove('selected');
			}
			if (evt.srcElement.classList.contains('vue3-results-item')) {
				evt.srcElement.classList.add('selected');
			}
		}
	}

	private keyHandler(evt) {
		// arrows up/down and Enter
		if (evt.keyCode === 38 || evt.keyCode === 40 || evt.keyCode === 13) {
			if (this.results?.length > 1) {
				const elem = this.autoCompleteContainer.querySelector('.vue3-results-item.selected');
				if (elem) {
					if (evt.keyCode === 38) {
						// up
						const hasSibling = elem.previousElementSibling;
						if (hasSibling) {
							elem.classList.remove('selected');
							elem.previousElementSibling.classList.add('selected');
						}
					} else if (evt.keyCode === 40) {
						// down
						const hasSibling = elem.nextElementSibling;
						if (hasSibling) {
							elem.classList.remove('selected');
							elem.nextElementSibling.classList.add('selected');
						}
					} else if (evt.keyCode === 13) {
						// Enter
						(elem as any).click();
					}
					return;
				}
			} else if (this.results?.length === 1 && evt.keyCode === 13) {
				const elem = this.autoCompleteContainer.querySelector('.vue3-results-item.selected');
				if (elem) {
					(elem as any).click();
				}
			}
		}
		this.tryFindFirstElem();
	}

	public tryFindFirstElem() {
		const threshhold = 10;
		let count = 0;
		if (this.interval) {
			clearInterval(this.interval);
		}
		this.interval = setInterval(() => {
			if (count >= threshhold) {
				clearInterval(this.interval);
				return;
			}
			count++;

			if (this.results?.length > 0) {
				const elem = this.autoCompleteContainer.querySelector('.vue3-results-item.selected');
				if (!elem) {
					const firstElem = this.autoCompleteContainer.querySelector('.vue3-results-item');
					if (firstElem) {
						firstElem.classList.add('selected');
						clearInterval(this.interval);
					}
				}
			}
		}, this.debounceEvents);
	}
}
